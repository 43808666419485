export default function getTrackingMetadata(path) {
    let category;
    let vertical;
    let subVertical;
    const tags = [];

    if (path.includes('bedrift')) {
        tags.push('business');
    } else {
        tags.push('personal');
    }

    if (path.startsWith('/bedriftskunde/jobb')) {
        category = 'job';
        vertical = 'job';
    } else if (path.startsWith('/bedriftskunde/motor')) {
        category = 'motor';
        vertical = 'motor';
    } else if (path.startsWith('/bedriftskunde/torget')) {
        category = 'bap';
        vertical = 'bap';
    } else if (path.startsWith('/bedriftskunde/eiendom')) {
        category = 'realestate';
        vertical = 'realestate';
    } else if (path.startsWith('/bedriftskunde/personlig-okonomi')) {
        category = 'personal finance';
        vertical = 'personal_finance';
    } else if (path.startsWith('/bedriftskunde/schibsted-annonseskole')) {
        category = 'b2b > annonseskole';
        vertical = 'b2b';
    } else if (path.startsWith('/bedriftskunde')) {
        category = 'b2b';
        vertical = 'b2b';
    } else if (path.startsWith('/realestate/')) {
        category = 'realestate';
        vertical = 'realestate';
    } else if (path.startsWith('/job/')) {
        category = 'job';
        vertical = 'job';
    } else if (path.startsWith('/bap/')) {
        category = 'bap';
        vertical = 'bap';
    } else if (path.startsWith('/motor/')) {
        category = 'motor';
        vertical = 'motor';
    } else if (path.startsWith('/okonomi/')) {
        category = 'personal finance';
        vertical = 'personal_finance';
    } else if (path.startsWith('/reise/')) {
        category = 'travel';
        vertical = 'travel';
    } else if (path.startsWith('/jobbeifinn')) {
        category = 'about finn';
        vertical = 'common_pages';
        subVertical = 'about_finn';
    } else if (path.startsWith('/finnspirasjon')) {
        category = 'n/a';
        vertical = 'common_pages';
    }

    return { category, vertical, subVertical, tags };
}
